<template>
  <transition name="blur">
    <aside class="mobile-sidebar" v-if="isOpen">
      <nav
        class="mobile-sidebar__nav"
        :style="isCoachesVisible && highlightedStyles('sidebar')"
      >
        <div class="mobile-sidebar__top"><slot name="top" /></div>
        <el-popover
          placement="right"
          trigger="click"
          popper-class="is-unstyled"
          :offset="-20"
        >
          <LayoutAsideCreateActions @closeSidebar="$emit('close')" />
          <template #reference>
            <button class="mobile-sidebar__nav-add">
              <img src="@images/plus.svg" alt="" class="new-task" />
              {{ $t('menu.add_task') }}
            </button>
          </template>
        </el-popover>
        <div class="mobile-sidebar__nav-list">
          <ui-aside-item
            v-for="{ label, to, iconName, coach } in menu"
            @closeSidebar="$emit('close')"
            :key="to"
            :icon-name="iconName"
            :to="to"
            :id="coach"
            :label="$t(label)"
            show-label
          />
        </div>
        <div class="mobile-sidebar__nav-footer">
          <router-link to="/logout">
            <button class="mobile-sidebar__logout">
              <IconLogout />
              {{ $t('menu.log_out') }}
            </button>
          </router-link>
        </div>
      </nav>
    </aside>
  </transition>
  <transition name="fade">
    <div
      class="layout"
      v-if="isOpen && !activeCoach.isEnabled"
      @click="$emit('close')"
    />
  </transition>
</template>

<script setup lang="ts">
import UiAsideItem from '@/components/ui/UiAsideItem.vue';
import IconLogout from '@/components/icon/nav/IconLogout.vue';
import { menu } from '@/consts/menu';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { storeToRefs } from 'pinia';
import LayoutAsideCreateActions from '@/components/layout/app/LayoutAsideCreateActions.vue';

const coachesStore = useCoachesStore();
const { highlightedStyles } = coachesStore;
const { activeCoach, isCoachesVisible } = storeToRefs(coachesStore);

withDefaults(defineProps<{ isOpen: boolean }>(), { isOpen: false });
defineEmits(['close']);
</script>

<style lang="scss" scoped>
.mobile-sidebar {
  width: 11.4rem;
  position: fixed;
  top: 0;
  z-index: 999;
  height: 100%;
  left: 0;

  &-wrap {
    position: relative;
  }

  &__top {
    min-height: 4.8rem;
    padding-left: 7rem;
  }

  &__nav {
    @include flex-column;
    align-items: flex-start;
    position: absolute;
    width: 37rem;
    padding: 1.6rem 0 2rem;
    top: 0;
    bottom: 0;
    background: #fcfcfc;
    box-shadow: 0 1rem 3rem #99999914;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    overflow-x: hidden;

    > img {
      align-self: flex-start;
    }

    &-add {
      @include flex-row(1rem);
      align-items: center;
      white-space: nowrap;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 3rem;
      color: #1a2a39;
      padding: 0.8rem 2.6rem;
      transition: all 0.2s linear;
      width: 100%;
      margin-top: 1rem;
    }

    &-list {
      @include flex-column;
      width: 100%;
    }

    &-footer {
      margin-top: auto;
      width: 100%;
    }
  }
  &__logout {
    @include flex-row(1rem);
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 3rem;
    color: #1a2a39;
    padding: 0 2.2rem;
    transition: background 0.2s linear;
    width: 100%;

    &:hover {
      svg {
        background: var(--color-primary);
        color: #f7f7f7;
      }
    }

    svg {
      width: 4.8rem;
      height: 4.8rem;
      padding: 1.2rem;
      border-radius: 50%;
      background: #fcfcfc;
      flex: 0 0 auto;
      transition: all 0.15s ease-in-out;
      box-shadow: 0 0.4rem 2rem rgba(#ababab40, 0.25);
    }
  }

  &__toggler {
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    margin-left: calc(1.3rem + 3.2rem);

    &.expanded {
      transform: rotate(180deg);
    }
  }
}
</style>

import { defineStore, storeToRefs } from 'pinia';
import { computed, markRaw } from 'vue';
import { CoachName, CoachCard } from '@/types/coaches';
import { sleep } from '@/utils/common';
import CoachAnalytics from '@/components/coaches/home/CoachAnalytics.vue';
import CoachTasks from '@/components/coaches/home/CoachTasks.vue';
import CoachNotifications from '@/components/coaches/home/CoachNotifications.vue';
import CoachNotes from '@/components/coaches/home/CoachNotes.vue';
import CoachMenu from '@/components/coaches/home/CoachMenu.vue';
import CoachWorkspaces from '@/components/coaches/home/CoachWorkspaces.vue';
import CoachCalendar from '@/components/coaches/home/CoachCalendar.vue';
import CoachCalendarTab from '@/components/coaches/calendar/CoachCalendarTab.vue';
import CoachAnalyticsTab from '@/components/coaches/analytics/CoachAnalyticsTab.vue';
import CoachUsersTab from '@/components/coaches/users/CoachUsersTab.vue';
import CoachNoteSchedule from '@/components/coaches/note/CoachNoteSchedule.vue';
import CoachBillingTab from '@/components/coaches/billing/CoachBillingTab.vue';
import CoachRecord from '@/components/coaches/createTask/CoachRecord.vue';
import CoachAssignment from '@/components/coaches/createTask/CoachAssignment.vue';
import CoachShareTask from '@/components/coaches/viewTask/CoachShareTask.vue';
import CoachStatus from '@/components/coaches/viewTask/CoachStatus.vue';
import CoachAboutTask from '@/components/coaches/sharedTask/CoachAboutTask.vue';
import CoachFiles from '@/components/coaches/sharedTask/CoachFiles.vue';
import CoachNoteRecord from '@/components/coaches/note/CoachNoteRecord.vue';
import CoachUsersInvite from '@/components/coaches/users/CoachUsersInvite.vue';
import router from '@/router';
import { useWorkspaceStore } from '@/stores/workspaces';
import { ACTIVE_COACH_ELEMENT_STYLES } from '@/consts/coach';
import CoachWeekStartGoogle from '@/components/coaches/agenda/week/CoachWeekStartGoogle.vue';
import CoachViewOnly from '@/components/coaches/agenda/shared/CoachViewOnly.vue';
import CoachWeekDrag from '@/components/coaches/agenda/week/CoachWeekDrag.vue';
import CoachWeekNightTime from '@/components/coaches/agenda/week/CoachWeekNightTime.vue';
import CoachCreate from '@/components/coaches/agenda/shared/CoachCreate.vue';
import CoachSync from '@/components/coaches/agenda/shared/CoachSync.vue';
import CoachWeekConnectGoogle from '@/components/coaches/agenda/week/CoachWeekConnectGoogle.vue';
import { useCoachCardFloating } from '@/hooks/coaches/useCoachCardFloating';
import { useCoachControl } from '@/hooks/coaches/useCoachControl';
import CoachDaySmart from '@/components/coaches/agenda/day/CoachDaySmart.vue';
import CoachDayCalendar from '@/components/coaches/agenda/day/CoachDayCalendar.vue';
import CoachExternalCalendars from '@/components/coaches/agenda/shared/CoachExternalCalendars.vue';
import CoachInternalCalendars from '@/components/coaches/agenda/shared/CoachInternalCalendars.vue';
import CoachDayToCalendarDrag from '@/components/coaches/agenda/day/CoachDayToCalendarDrag.vue';
import CoachDayToHeadDrag from '@/components/coaches/agenda/day/CoachDayToHeadDrag.vue';
import { useUserStore } from '@/stores/user';

export const useCoachesStore = defineStore(
  'coaches',
  () => {
    const workspacesStore = useWorkspaceStore();
    const { isWidgetVisible } = storeToRefs(workspacesStore);

    const userStore = useUserStore();
    const { isProfileWidgetShow } = storeToRefs(userStore);

    const coaches = computed<Record<CoachName, CoachCard[]>>(() => ({
      dashboard: [
        {
          component: markRaw(CoachTasks),
          attachTo: 'dashboard-tasks',
        },
        {
          component: markRaw(CoachCalendar),
          attachTo: 'dashboard-calendar',
        },
        {
          component: markRaw(CoachNotifications),
          attachTo: 'dashboard-notifications',
        },
        {
          component: markRaw(CoachAnalytics),
          attachTo: 'dashboard-analytics',
        },
        {
          component: markRaw(CoachNotes),
          attachTo: 'dashboard-notes',
        },
        {
          component: markRaw(CoachMenu),
          attachTo: 'dashboard-menu',
          onBefore: () => (isProfileWidgetShow.value = true),
          onAfter: () => (isProfileWidgetShow.value = false),
        },
        {
          component: markRaw(CoachWorkspaces),
          attachTo: 'dashboard-workspaces',
          onBefore: () => (isWidgetVisible.value = true),
          onAfter: () => (isWidgetVisible.value = false),
        },
      ],
      myTasks: [
        {
          component: markRaw(CoachCalendarTab),
          attachTo: 'calendar-tab',
          highlight: ['sidebar'],
        },
      ],
      analytics: [
        {
          component: markRaw(CoachAnalyticsTab),
          attachTo: 'analytics',
          highlight: ['sidebar'],
        },
      ],
      users: [
        {
          component: markRaw(CoachUsersTab),
          attachTo: 'users-tab',
          highlight: ['sidebar'],
        },
        {
          component: markRaw(CoachUsersInvite),
          attachTo: 'users-invites',
          onBefore: async () => {
            if (router.currentRoute.value.name === 'Invitations') return;
            await router.push({ name: 'Invitations' });
            await sleep(500);
            return router.isReady();
          },
        },
      ],
      billing: [
        {
          component: markRaw(CoachBillingTab),
          attachTo: 'billing-tab',
          highlight: ['sidebar'],
        },
      ],
      createTask: [
        { component: markRaw(CoachRecord), attachTo: 'task-record' },
        { component: markRaw(CoachAssignment), attachTo: 'task-assigment' },
      ],
      viewTask: [
        { component: markRaw(CoachShareTask), attachTo: 'view-share' },
        { component: markRaw(CoachStatus), attachTo: 'view-status' },
      ],
      sharedTask: [
        { component: markRaw(CoachAboutTask), attachTo: 'view-share' },
        { component: markRaw(CoachFiles), attachTo: 'shared-files' },
      ],
      createNote: [
        { component: markRaw(CoachNoteRecord), attachTo: 'note-record' },
      ],
      scheduleNote: [
        { component: markRaw(CoachNoteSchedule), attachTo: 'note-schedule' },
      ],
      note: [],
      monthAgenda: [],
      weekAgenda: [
        {
          component: markRaw(CoachWeekStartGoogle),
          highlight: ['workspaces-row'],
          attachTo: 'week-column-1',
          onBefore: async () => {
            setTimeout(() =>
              document
                .querySelector('#week-body')
                ?.scrollTo({ behavior: 'smooth', top: 0 }),
            );
          },
          meta: { placement: ['right-start'] },
        },
        {
          component: markRaw(CoachWeekDrag),
          highlight: ['week-head', 'week-column'],
          attachTo: 'column-row-1_0',
          onBefore: async () => {
            setTimeout(() =>
              document
                .querySelector('#week-body')
                ?.scrollTo({ behavior: 'smooth', top: 0 }),
            );
          },
          meta: { isDragCoach: true, placement: ['right'], noScroll: true },
        },
        {
          component: markRaw(CoachViewOnly),
          attachTo: '_18336-week',
          onBefore: async () => {
            setTimeout(() =>
              document
                .querySelector('#week-body')
                ?.scrollTo({ behavior: 'smooth', top: 0 }),
            );
          },
        },
        {
          component: markRaw(CoachInternalCalendars),
          attachTo: 'internal-list',
          highlight: ['internal-calendars'],
        },
        {
          component: markRaw(CoachWeekConnectGoogle),
          attachTo: 'external-list',
          highlight: ['external-calendars'],
          meta: { isConnectCoach: true, placement: ['bottom'] },
        },
        {
          component: markRaw(CoachExternalCalendars),
          attachTo: 'external-list',
          highlight: ['external-calendars'],
        },
        {
          component: markRaw(CoachSync),
          attachTo: 'sync-calendars',
          highlight: ['external-calendars'],
        },
        {
          component: markRaw(CoachWeekNightTime),
          highlight: ['time-column'],
          attachTo: 'night-time',
          onBefore: async () => {
            setTimeout(() =>
              document
                .querySelector('#week-body')
                ?.scrollTo({ behavior: 'smooth', top: 0 }),
            );
          },
        },
        {
          component: markRaw(CoachCreate),
          attachTo: 'week-column-2',
          onBefore: async () => {
            document
              .querySelector<HTMLDivElement>('#week-column-2')
              ?.classList.add('coach-backlight');
            setTimeout(() =>
              document
                .querySelector('#week-body')
                ?.scrollTo({ behavior: 'smooth', top: 0 }),
            );
          },
          onAfter: () => {
            document
              .querySelector<HTMLDivElement>('#week-column-2')
              ?.classList.remove('coach-backlight');
          },
        },
      ],
      dayAgenda: [
        {
          component: markRaw(CoachDaySmart),
          attachTo: 'day-body',
          onBefore: () =>
            setTimeout(() =>
              document
                .querySelectorAll('#day-body, #day-wrapper')
                .forEach((el) => el.scrollTo({ behavior: 'smooth', top: 0 })),
            ),
          meta: { noScroll: true, placement: ['left-start'] },
        },
        {
          component: markRaw(CoachDayCalendar),
          highlight: ['day-calendar-head'],
          attachTo: 'day-calendar',
          onBefore: () =>
            setTimeout(() =>
              document
                .querySelectorAll('#day-sidebar, #day-wrapper')
                .forEach((el) => el?.scrollTo({ behavior: 'smooth', top: 0 })),
            ),
          meta: { noScroll: true },
        },
        {
          component: markRaw(CoachInternalCalendars),
          attachTo: 'internal-list',
          highlight: ['internal-calendars'],
          meta: { noScroll: true },
        },
        {
          component: markRaw(CoachWeekConnectGoogle),
          attachTo: 'external-list',
          highlight: ['external-calendars'],
          meta: { isConnectCoach: true, noScroll: true },
        },
        {
          component: markRaw(CoachExternalCalendars),
          attachTo: 'external-list',
          highlight: ['external-calendars'],
          meta: { noScroll: true },
        },
        {
          component: markRaw(CoachSync),
          attachTo: 'sync-calendars',
          highlight: ['external-calendars'],
          meta: { placement: ['right', 'left', 'top'], noScroll: true },
        },
        ...(document.body.clientWidth > 1440
          ? [
              {
                component: markRaw(CoachDayToCalendarDrag),
                highlight: ['day-calendar-head', 'day-calendar'],
                attachTo: '_180923-day',
                onBefore: async () => {
                  setTimeout(() =>
                    document
                      .querySelector('#day-wrapper')
                      ?.scrollTo({ behavior: 'smooth', top: 0 }),
                  );
                },
                meta: {
                  isDragCoach: true,
                  placement: ['right'],
                  noScroll: true,
                },
              },
            ]
          : []),
        {
          component: markRaw(CoachDayToHeadDrag),
          highlight: ['day-body'],
          attachTo: '_180923-day',
          onBefore: () =>
            setTimeout(() =>
              document
                .querySelector('#day-body')
                ?.scrollTo({ behavior: 'smooth', top: 0 }),
            ),
          meta: { placement: ['right'], noScroll: true },
        },
        {
          component: markRaw(CoachViewOnly),
          attachTo: '_18092-day',
          onBefore: async () => {
            setTimeout(() =>
              document
                .querySelector('#day-body')
                ?.scrollTo({ behavior: 'smooth', top: 0 }),
            );
          },
          meta: { noScroll: true },
        },
        {
          component: markRaw(CoachCreate),
          attachTo: 'day-columns',
          onBefore: async () => {
            document
              .querySelector<HTMLDivElement>('#day-columns')
              ?.classList.add('coach-backlight');

            setTimeout(() =>
              document
                .querySelector('#day-body')
                ?.scrollTo({ behavior: 'smooth', top: 0 }),
            );
          },
          onAfter: () => {
            document
              .querySelector<HTMLDivElement>('#day-columns')
              ?.classList.remove('coach-backlight');
          },
          meta: { placement: ['left'], noTasksInColumn: true, noScroll: true },
        },
      ],
      files: [],
    }));

    const {
      isCoachesEnabled,
      isCoachesVisible,
      activeCoach,
      activeCard,
      disableCoaches,
      disableActiveCoach,
      enableActiveCoach,
      resetCoach,
      resolveCoach,
      setCoach,
      setStep,
    } = useCoachControl(coaches);
    const { reference, trigger } = useCoachCardFloating(
      activeCard,
      isCoachesVisible,
    );

    const highlightedStyles = (item: string) => {
      if (activeCard.value?.highlight?.includes(item))
        return ACTIVE_COACH_ELEMENT_STYLES;
    };

    return {
      isCoachesEnabled,
      isCoachesVisible,
      activeCoach,
      activeCard,
      setCoach,
      resetCoach,
      disableActiveCoach,
      enableActiveCoach,
      disableCoaches,
      setStep,
      resolveCoach,
      highlightedStyles,
      trigger,
      reference,
    };
  },
  { persist: { paths: ['activeCoach', 'isCoachesEnabled'] } },
);

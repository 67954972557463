import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createBlock as _createBlock, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@images/expand.svg'
import _imports_1 from '@images/plus.svg'


const _hoisted_1 = { class: "sidebar__nav-add" }
const _hoisted_2 = { class: "sidebar__nav-list" }
const _hoisted_3 = { class: "sidebar__nav-footer" }
const _hoisted_4 = { class: "sidebar__logout" }

import UiAsideItem from '@/components/ui/UiAsideItem.vue';
import IconLogout from '@/components/icon/nav/IconLogout.vue';
import { ref } from 'vue';
import { menu } from '@/consts/menu';
import { useCoachesStore } from '@/stores/coaches/coaches';
import LayoutAsideCreateActions from '@/components/layout/app/LayoutAsideCreateActions.vue';
import { storeToRefs } from 'pinia';


export default /*@__PURE__*/_defineComponent({
  __name: 'LayoutAside',
  setup(__props) {

const coachesStore = useCoachesStore();
const { highlightedStyles } = coachesStore;
const { isCoachesVisible } = storeToRefs(coachesStore);

const isMenuExpanded = ref(false);

return (_ctx: any,_cache: any) => {
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("aside", {
    class: "sidebar",
    style: _normalizeStyle(_unref(isCoachesVisible) && _unref(highlightedStyles)('sidebar'))
  }, [
    _withDirectives((_openBlock(), _createElementBlock("nav", {
      class: "sidebar__nav",
      style: _normalizeStyle(
        isMenuExpanded.value && {
          width: _ctx.$i18n.locale === 'ru' ? '30rem' : '25rem',
        }
      )
    }, [
      _createElementVNode("img", {
        src: _imports_0,
        class: _normalizeClass(["sidebar__toggler", { expanded: isMenuExpanded.value }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (isMenuExpanded.value = !isMenuExpanded.value)),
        alt: ""
      }, null, 2),
      _createVNode(_component_el_popover, {
        placement: "right",
        trigger: "click",
        "popper-class": "is-unstyled",
        offset: -20
      }, {
        reference: _withCtx(() => [
          _createElementVNode("button", _hoisted_1, [
            _cache[1] || (_cache[1] = _createElementVNode("img", {
              src: _imports_1,
              class: "new-task",
              alt: ""
            }, null, -1)),
            (isMenuExpanded.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('menu.add_task')), 1)
                ], 64))
              : _createCommentVNode("", true)
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(LayoutAsideCreateActions)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menu), ({ label, to, iconName, coach }) => {
          return (_openBlock(), _createBlock(UiAsideItem, {
            key: to,
            "icon-name": iconName,
            to: to,
            label: _ctx.$t(label),
            "show-label": isMenuExpanded.value,
            id: coach
          }, null, 8, ["icon-name", "to", "label", "show-label", "id"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, { to: "/logout" }, {
          default: _withCtx(() => [
            _createElementVNode("button", _hoisted_4, [
              _createVNode(_component_el_tooltip, {
                content: _ctx.$t('menu.log_out'),
                placement: "right",
                effect: "customized"
              }, {
                default: _withCtx(() => [
                  _createVNode(IconLogout)
                ]),
                _: 1
              }, 8, ["content"]),
              (isMenuExpanded.value)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('menu.log_out')), 1)
                  ], 64))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ])
    ], 4)), [
      [_directive_click_outside, () => isMenuExpanded.value && (isMenuExpanded.value = false)]
    ])
  ], 4))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import UiCoachCard from '@/components/ui/UiCoachCard.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'CoachInternalCalendars',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiCoachCard, {
    class: "coach-internal-calendars",
    "max-width": "40rem"
  }, {
    title: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "coach-internal-calendars__title" }, " List of Voiset workspaces ", -1)
    ])),
    content: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "coach-internal-calendars__content" }, " Control the display of Voiset workspaces on the calendar and create new workspaces. ", -1)
    ])),
    _: 1
  }))
}
}

})
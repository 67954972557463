import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@images/integrations/google/voiset-logo.webp'
import _imports_1 from '@images/integrations/google/two-way-arrows.svg'
import _imports_2 from '@images/integrations/google/google-logo.svg'
import _imports_3 from '@images/integrations/google/one-way-arrow.svg'


import UiCoachCard from '@/components/ui/UiCoachCard.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'CoachSync',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiCoachCard, {
    class: "coach-week-sync",
    "max-width": "37rem"
  }, {
    title: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "coach-week-sync__title" }, " Types of sync of connected calendars ", -1)
    ])),
    content: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "coach-week-sync__content" }, [
        _createElementVNode("div", null, [
          _createTextVNode(" Two-way sync "),
          _createElementVNode("img", {
            class: "logo",
            src: _imports_0,
            alt: ""
          }),
          _createTextVNode(" Voiset "),
          _createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }),
          _createElementVNode("img", {
            class: "logo",
            src: _imports_2,
            alt: ""
          }),
          _createTextVNode(" Google ")
        ]),
        _createTextVNode(" All your changes and new tasks are displayed in both Voiset and the third-party calendar. "),
        _createElementVNode("div", null, [
          _createTextVNode(" One-way sync "),
          _createElementVNode("img", {
            class: "logo",
            src: _imports_2,
            alt: ""
          }),
          _createTextVNode(" Google "),
          _createElementVNode("img", {
            src: _imports_3,
            alt: ""
          }),
          _createElementVNode("img", {
            class: "logo",
            src: _imports_0,
            alt: ""
          }),
          _createTextVNode(" Voiset ")
        ]),
        _createTextVNode(" Tasks are displayed in your schedule from a third-party calendar, but you can't edit them, change the time, or create new tasks. ")
      ], -1)
    ])),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@images/agenda/coaches/night-time.svg'


import UiCoachCard from '@/components/ui/UiCoachCard.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'CoachWeekNightTime',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiCoachCard, { class: "coach-week-night-time" }, {
    title: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "coach-week-night-time__title" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }),
        _createTextVNode(" Night time ")
      ], -1)
    ])),
    content: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "coach-week-night-time__content" }, " You can open and close night time so that your attention is focused on tasks. Click and try how it works. ", -1)
    ])),
    _: 1
  }))
}
}

})
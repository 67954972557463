import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "coach-week-drag__content" }
const _hoisted_2 = {
  key: 0,
  loop: "",
  muted: "",
  autoplay: "",
  playsinline: "",
  src: "https://storage.googleapis.com/public_media_bucket/coaches/day-drag-head.mp4",
  width: "100%"
}

import UiCoachCard from '@/components/ui/UiCoachCard.vue';
import { inject, Ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CoachDayToHeadDrag',
  setup(__props) {

const isMobileResolution = inject<Ref<boolean>>('isMobileResolution');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiCoachCard, {
    class: "coach-week-drag",
    "max-width": _unref(isMobileResolution) ? '30rem' : '35vw'
  }, {
    title: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "coach-week-drag__title" }, " Drag and drop tasks from your schedule to your task list for the day. ", -1)
    ])),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createTextVNode(" Tasks can be dragged back and forth, this is handy for quickly planning your day. Try it now! ")),
        (!_unref(isMobileResolution))
          ? (_openBlock(), _createElementBlock("video", _hoisted_2))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["max-width"]))
}
}

})
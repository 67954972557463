import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import UiCoachCard from '@/components/ui/UiCoachCard.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'CoachExternalCalendars',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiCoachCard, { class: "coach-week-external-calendars" }, {
    title: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "coach-week-external-calendars__title" }, " List of your connected calendars ", -1)
    ])),
    content: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "coach-week-external-calendars__content" }, " This displays your list of third-party calendars and accounts. Control their display on the calendar. ", -1)
    ])),
    _: 1
  }))
}
}

})
<template>
  <div class="widget-profile">
    <el-popover
      placement="bottom"
      popper-class="user__modal"
      width="260"
      :trigger="isCoachesVisible ? undefined : 'click'"
      :visible="isProfileWidgetShow"
      @update:visible="!isCoachesVisible && (isProfileWidgetShow = $event)"
    >
      <UserDropDownMenu
        @on-close="toggleShow(false)"
        @open-feedback="openFeedback"
        @open-contact="openContact"
        id="dashboard-menu"
      />
      <template #reference>
        <div class="widget-profile__container">
          <ui-avatar
            :avatar-link="userStore.userData?.avatar?.uuidLink"
            :fallback-name="userName"
          />
          <icon-chevron
            class="ui-filter__button-arrow"
            :direction="chevronDirection"
          />
        </div>
      </template>
    </el-popover>
    <ModalFeedback
      :is-open="isOpenFeedbackModal"
      @on-close="isOpenFeedbackModal = false"
      @open-contact="openContact"
    />
    <ModalHelp
      :is-open="isOpenContactModal"
      @on-close="isOpenContactModal = false"
      @open-feedback="openFeedback"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';

import IconChevron from '@/components/icon/IconChevron.vue';
import UserDropDownMenu from '@/components/user/UserDropDownMenu.vue';
import ModalHelp from '@/components/modals/Support/ModalHelp.vue';
import ModalFeedback from '@/components/modals/Support/ModalFeedback.vue';

import { useUserStore } from '@/stores/user';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { storeToRefs } from 'pinia';
import { concatName } from '@/utils/text';

const userStore = useUserStore();
const { isProfileWidgetShow } = storeToRefs(userStore);
const userName = computed(() =>
  concatName(userStore.userData?.firstName, userStore.userData?.lastName),
);

const coachesStore = useCoachesStore();
const { isCoachesVisible } = storeToRefs(coachesStore);

const isOpenContactModal = ref(false);
const isOpenFeedbackModal = ref(false);

const toggleShow = (val: boolean) => (isProfileWidgetShow.value = val);

const openFeedback = () => {
  toggleShow(false);
  isOpenContactModal.value = false;
  isOpenFeedbackModal.value = true;
};

const openContact = () => {
  toggleShow(false);
  isOpenFeedbackModal.value = false;
  isOpenContactModal.value = true;
};

const chevronDirection = computed(() => {
  return isProfileWidgetShow.value ? 'top' : 'bottom';
});
</script>

<style lang="scss" scoped>
.widget-profile {
  display: flex;
  align-items: center;
  column-gap: 0.6rem;
  cursor: pointer;

  &__container {
    @include flex-row(1rem);
    align-items: center;
  }

  &__avatar {
    @include avatar;

    &--default {
      position: relative;
      background-image: url('@/assets/images/pictogram/contact.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-color: #dde3ec;
      background-size: 2.7rem;
    }
  }
}
</style>

import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ui-coach-card__title"
}
const _hoisted_2 = { class: "ui-coach-card__content" }
const _hoisted_3 = { class: "ui-coach-card__bar-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "ui-coach-card__bar"
}
const _hoisted_5 = {
  key: 0,
  class: "ui-coach-card__steps"
}
const _hoisted_6 = { class: "ui-coach-card__footer" }
const _hoisted_7 = { class: "ui-coach-card__buttons" }

import { TranslateResult } from 'vue-i18n';
import { computed, inject, onMounted, ref, Ref, toRefs } from 'vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { storeToRefs } from 'pinia';
import IconClose from '@/components/icon/IconClose.vue';

type CoachCardProps = {
  title?: string | TranslateResult;
  content?: string;
  maxWidth?: string;
  doubleTail?: boolean;
  compactMode?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'UiCoachCard',
  props: {
    title: {},
    content: {},
    maxWidth: { default: '35rem' },
    doubleTail: { type: Boolean },
    compactMode: { type: Boolean, default: false }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "5a9e0607": (_ctx.maxWidth)
}))

const card = ref<HTMLDivElement>();
onMounted(() => card.value?.focus());

const props = __props;
const { doubleTail } = toRefs(props);

const position = inject<Ref<'top' | 'bottom' | 'left' | 'right'>>('position');

const coachesStore = useCoachesStore();
const { setStep } = coachesStore;
const { isCoachesEnabled, activeCoach } = storeToRefs(coachesStore);

const progressWidthStyle = computed(() => {
  const width = activeCoach.value.activeStep / activeCoach.value.steps;

  return {
    transition: 'width 0.15s ease-in-out',
    width: `${width * 100}%`,
  };
});

return (_ctx: any,_cache: any) => {
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['ui-coach-card', _unref(position), { 'double-tail': _unref(doubleTail) }]),
    ref_key: "card",
    ref: card,
    tabindex: "0",
    onKeyup: [
      _cache[3] || (_cache[3] = _withKeys(($event: any) => (
      _unref(activeCoach).activeStep !== 1 && _unref(setStep)(--_unref(activeCoach).activeStep)
    ), ["left"])),
      _cache[4] || (_cache[4] = _withKeys(($event: any) => (_unref(setStep)(++_unref(activeCoach).activeStep)), ["right"]))
    ]
  }, [
    _createVNode(IconClose, {
      class: "ui-coach-card__close",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (isCoachesEnabled.value = !_unref(isCoachesEnabled)), ["stop"]))
    }),
    _renderSlot(_ctx.$slots, "title", {}, () => [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "content", {}, () => [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.content), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.compactMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "ui-coach-card__progress",
              style: _normalizeStyle(progressWidthStyle.value)
            }, null, 4)
          ]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.compactMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(`${_unref(activeCoach).activeStep}/${_unref(activeCoach).steps}`), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "footer", {}, () => [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          (_unref(activeCoach).activeStep !== 1)
            ? (_openBlock(), _createBlock(_component_ui_button, {
                key: 0,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(setStep)(--_unref(activeCoach).activeStep))),
                plain: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('coaches.buttons.back')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ui_button, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(setStep)(++_unref(activeCoach).activeStep)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(
                `coaches.buttons.${
                  _unref(activeCoach).activeStep === _unref(activeCoach).steps
                    ? 'got-it'
                    : 'next'
                }`,
              )), 1)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ], 34))
}
}

})
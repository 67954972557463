<script lang="ts" setup>
import WidgetShareTask from '@/components/widget/WidgetShareTask.vue';
import IconShare from '@/components/icon/IconShare.vue';
import IconMessage from '@/components/icon/IconMessage.vue';
import IconClose from '@/components/icon/IconClose.vue';
import { ref } from 'vue';
import HeaderActionButton from './HeaderActionButton.vue';
import IconQuestion from '@/components/icon/IconQuestion.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import IconFavorite from '@/components/icon/IconFavorite.vue';
import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import { storeToRefs } from 'pinia';
import { useDebounceFn } from '@vueuse/core';

const toggleComments = () => {
  if (state.value.isShowComments) {
    viewTask.closeComments();
    useDebounceFn(viewTask.clearComments, 500)();
  } else {
    viewTask.openComments();
    viewTask.getComments();
  }
};

const couchStore = useCoachesStore();
const shareLink = ref(false);

const viewTask = useViewTaskStore();
const { state, activeTask } = storeToRefs(viewTask);

const setShareLinkVisible = (val: boolean) => (shareLink.value = val);
</script>

<template>
  <div :class="$style.wrapper">
    <el-tooltip
      v-if="!state.isShared"
      content="Add task to favourites"
      placement="bottom"
      :disabled="viewTask.activeTask.isMarked"
      effect="customized"
    >
      <IconFavorite
        :class="$style.favorite"
        :isActive="viewTask.activeTask.isMarked"
        @click="viewTask.setActiveTaskFields('isMarked', !activeTask.isMarked)"
      />
    </el-tooltip>
    <HeaderActionButton
      :active="state.isShowComments"
      :tooltip="
        $t(
          !state.isShowComments
            ? 'view_modal_task.comments'
            : 'view_modal_task.hide_comments',
        )
      "
      @click="toggleComments"
    >
      <IconMessage />
    </HeaderActionButton>
    <el-popover
      v-if="!state.isShared && !state.isTaskLoading"
      :visible="shareLink"
      @update:visible="setShareLinkVisible"
      placement="bottom"
      popper-class="share__popup"
      width="100%"
      trigger="click"
    >
      <WidgetShareTask @on-close="setShareLinkVisible(false)" />
      <template #reference>
        <div>
          <HeaderActionButton
            v-if="!state.isTaskLoading"
            :tooltip="$t('view_modal_task.share')"
          >
            <IconShare />
          </HeaderActionButton>
        </div>
      </template>
    </el-popover>
    <HeaderActionButton
      :tooltip="$t('tooltips')"
      :active="couchStore.isCoachesEnabled"
      @click="couchStore.resolveCoach"
    >
      <IconQuestion width="18" height="18" />
    </HeaderActionButton>
    <button
      v-if="!state.isShared"
      :class="$style.close"
      @click="state.isOpenModal && viewTask.clear()"
    >
      <IconClose />
    </button>
  </div>
</template>

<style lang="scss" module>
.wrapper {
  height: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
}
.btn {
  display: flex;
  align-items: center;
  @include f14;
  line-height: 1.1;
  min-height: 2.8rem;
  border-radius: 6px;
  transition: all 0.1s linear;
  padding: 0 1rem;
  margin-right: 1rem;

  &:hover {
    background-color: #ecfadb;
  }

  svg {
    margin-right: 0.85rem;
  }

  &.share {
    margin-right: 1.25rem;
  }
}

.close {
  width: 2.8rem;
  height: 2.8rem;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.favorite {
  cursor: pointer;
  outline: none;
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, inject, provide, Ref, watch } from 'vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { storeToRefs } from 'pinia';
import { templateRef } from '@vueuse/core';
import {
  autoPlacement,
  autoUpdate,
  offset,
  Placement,
  shift,
  useFloating,
} from '@floating-ui/vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CoachActive',
  setup(__props) {

const coachesStore = useCoachesStore();
const { activeCard, isCoachesVisible, reference } = storeToRefs(coachesStore);
const card = templateRef<HTMLElement>('card');

const middleware = computed(() => [
  offset(25),
  autoPlacement({
    ...(activeCard.value?.meta?.placement && {
      allowedPlacements: activeCard.value?.meta?.placement as Placement,
    }),
  }),
  shift({ padding: 5, crossAxis: true }),
]);

const { floatingStyles, placement } = useFloating(reference, card, {
  middleware,
  whileElementsMounted: autoUpdate,
  strategy: 'fixed',
});

provide(
  'position',
  computed(() => activeCard.value.attachTo && placement.value),
);

const isMobileResolution = inject<Ref<boolean>>('isMobileResolution');
watch(
  () => isMobileResolution?.value,
  () => {
    if (!activeCard.value?.attachTo) return;
    coachesStore.disableCoaches();
    document
      .querySelector(`#${activeCard.value.attachTo}`)
      ?.classList.remove('coach-highlight');
  },
);

return (_ctx: any,_cache: any) => {
  return (_unref(activeCard) && _unref(isCoachesVisible))
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(activeCard).component), {
        key: 0,
        style: _normalizeStyle(_unref(floatingStyles)),
        ref_key: "card",
        ref: card
      }, null, 8, ["style"]))
    : _createCommentVNode("", true)
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useCoachesStore } from '@/stores/coaches/coaches';
import { storeToRefs } from 'pinia';
import IconQuestion from '@/components/icon/IconQuestion.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'WidgetCoach',
  setup(__props) {

const store = useCoachesStore();
const { isCoachesEnabled } = storeToRefs(store);

return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(_component_el_tooltip, {
    content: _ctx.$t('tooltips'),
    placement: "bottom",
    effect: "customized"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["widget-coach", { enabled: _unref(isCoachesEnabled) }]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_unref(store).resolveCoach && _unref(store).resolveCoach(...args)), ["stop"]))
      }, [
        _createVNode(IconQuestion)
      ], 2)
    ]),
    _: 1
  }, 8, ["content"]))
}
}

})
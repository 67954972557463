import { ISubordinatesFilters, TSubordinatesSorts } from '@/types/subordinate';
import { tableSorting } from '@/utils/table';
import { defineStore } from 'pinia';
import { reactive, ref } from 'vue';

export const useSubordinatesFiltersStore = defineStore(
  'subordinateFilters',
  () => {
    const initialFilters = (): ISubordinatesFilters => ({
      pageNum: 1,
      pageSize: 10,

      filter: '',
    });
    const filters = ref<ISubordinatesFilters>(initialFilters());

    const sorts = reactive<TSubordinatesSorts>({
      columnSort: '',
      order: null,
    });

    function setFilter<FilterField extends keyof typeof filters>(
      field: FilterField,
      values: (typeof filters)[FilterField],
    ) {
      filters[field] = values;
    }

    function setSort(columnSort: TSubordinatesSorts['columnSort']) {
      tableSorting<TSubordinatesSorts>(sorts, columnSort);
    }

    function setPage(page: number) {
      filters.value.pageNum = page;
    }

    function setPageSize(pageSize: number) {
      filters.value.pageSize = pageSize;
    }

    function refreshFilters() {
      filters.value = initialFilters();
    }

    return {
      setSort,
      filters,
      initialFilters,
      setFilter,
      setPage,
      setPageSize,
      refreshFilters,
      sorts,
    };
  },
);

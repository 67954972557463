export const ACTIVE_COACH_ELEMENT_STYLES = {
  zIndex: 9991,
  pointerEvents: 'none',
};

export const NO_STICKY_ELEMENT_STYLES = {
  position: 'relative',
  pointerEvents: 'none',
  zIndex: 'unset',
};

import { watchTriggerable } from '@vueuse/core';
import { ComputedRef, nextTick, Ref, ref } from 'vue';
import { scrollToSelector } from '@/utils/scroll';
import { CoachCard } from '@/types/coaches';

export const useCoachCardFloating = (
  activeCard: ComputedRef<CoachCard>,
  isCoachesVisible: Ref<boolean>,
) => {
  const reference = ref<Element | null>(null);
  const HIGHLIGHT_CLASS = 'coach-highlight';
  const trigger = ref<undefined | (() => void)>();

  const { trigger: triggerFn } = watchTriggerable(
    [() => activeCard.value, () => isCoachesVisible.value],
    async ([current, enabled], [prev]) => {
      const prevRef = prev?.attachTo
        ? document.querySelector(`#${prev.attachTo}`)
        : null;

      if (!enabled || (prev?.attachTo && prev.attachTo !== current?.attachTo)) {
        await prev?.onAfter?.();
        prevRef?.classList.remove(HIGHLIGHT_CLASS);
      }

      if (!enabled || !current) return;
      await current?.onBefore?.();
      const currentRef = current?.attachTo
        ? document.querySelector(`#${current.attachTo}`)
        : null;

      if (!current?.meta?.noScroll)
        await nextTick(() => scrollToSelector(`#${current.attachTo}`));

      currentRef?.classList.add(HIGHLIGHT_CLASS);
      reference.value = currentRef;
    },
    { immediate: true, deep: true },
  );

  trigger.value = triggerFn;

  return { reference, trigger };
};

import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@images/agenda/coaches/coach-connect.webp'


import UiCoachCard from '@/components/ui/UiCoachCard.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'CoachWeekConnectGoogle',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiCoachCard, { class: "coach-week-start-google" }, {
    content: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "coach-week-start-google__content" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }),
        _createElementVNode("div", { class: "coach-week-start-google__title" }, "Connect your calendars"),
        _createElementVNode("div", { class: "coach-week-start-google__description" }, " You can view tasks from all workspaces and connected calendars in the schedule. With two clicks, you can connect your Google calendar and keep all tasks in one place. ")
      ], -1)
    ])),
    _: 1
  }))
}
}

})
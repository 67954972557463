<template>
  <div class="ui-filter">
    <div class="ui-filter__header">
      <span>{{ getLabel(label) }}</span>
      <div class="ui-filter__clear" @click="resetFilters">
        {{ $t('tasks.filters.clear') }}
      </div>
    </div>
    <div class="ui-filter-control">
      <el-checkbox v-model="isAllUsers">
        <span class="ui-filter-control__title">{{ $t('all_users') }}</span>
        <span class="ui-filter-control__count">{{ options?.length }}</span>
      </el-checkbox>
    </div>
    <div class="ui-filter-dropdown ui-filter-dropdown--scroll">
      <div
        class="ui-filter-dropdown__row"
        v-for="option in options"
        :key="option?.value || option?.label"
      >
        <el-checkbox-group
          :model-value="filtersState?.[filterField]"
          @update:model-value="setFilter?.(filterField, $event)"
        >
          <el-checkbox :model-value="option.value" :value="option.value">
            <ui-avatar
              :avatar-link="option?.avatarUrl"
              size="3rem"
              :fallback-name="getLabel(option.label)"
            />
            <span class="text-ellipsis">
              {{ getLabel(option.label) || option.value }}
            </span>
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { TableFilter } from '@/types/common';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import { useI18n } from 'vue-i18n';

const { t, te } = useI18n();

const getLabel = (label?: string) => (label && te(label) ? t(label) : label);

const props = defineProps<TableFilter>();
const isAllUsers = computed({
  get() {
    return filtersState?.[props.filterField]?.length === props.options?.length;
  },
  set() {
    if (isAllUsers.value) {
      resetFilters();
    } else {
      setFilter?.(
        props.filterField,
        props.options.map((o) => o.value),
      );
    }
  },
});

const filtersState = inject<Record<string, unknown[]>>('filtersState');
const setFilter = inject<(...args: unknown[]) => void>('setFilter');

const resetFilters = () => {
  const filter = filtersState?.[props.filterField];
  setFilter && setFilter(props.filterField, filter instanceof Array ? [] : '');
};
</script>

<style scoped lang="scss"></style>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createSlots as _createSlots, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@images/integrations/google/voiset-logo.webp'
import _imports_1 from '@images/gear.svg'
import _imports_2 from '@images/workspaces/new.svg'
import _imports_3 from '@images/workspaces/join.svg'


const _hoisted_1 = { class: "widget-workspaces" }
const _hoisted_2 = {
  class: "widget-workspaces__options options",
  id: "dashboard-workspaces"
}
const _hoisted_3 = { class: "options__row" }
const _hoisted_4 = { class: "disabled-text" }
const _hoisted_5 = { class: "options__header" }
const _hoisted_6 = { class: "widget-workspaces__label" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "widget-workspaces__label sub" }
const _hoisted_9 = { class: "options__list with-scroll" }
const _hoisted_10 = {
  class: "options__row",
  style: {"margin-top":"1rem"}
}
const _hoisted_11 = { class: "disabled-text" }
const _hoisted_12 = { class: "options__control" }
const _hoisted_13 = { class: "widget-workspaces__reference-current" }
const _hoisted_14 = { class: "widget-workspaces__reference-name" }

import { ref, watch } from 'vue';
import { useWorkspaceStore } from '@/stores/workspaces';
import { storeToRefs } from 'pinia';
import IconMarker from '@/components/icon/IconMarker.vue';
import WorkspaceItem from '@/components/workspaces/WorkspaceItem.vue';
import ModalCreateWorkspace from '@/components/modals/Workspaces/Create/ModalCreateWorkspace.vue';
import ModalEditWorkspaces from '@/components/modals/Workspaces/ModalEditWorkspaces.vue';
import ModalPlanBuy from '@/components/modals/Billing/ModalPlanBuy.vue';
import { useUserStore } from '@/stores/user';
import IconChevron from '@/components/icon/IconChevron.vue';
import ModalJoinWorkspace from '@/components/modals/Workspaces/ModalJoinWorkspace.vue';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import ModalEditGuestWorkspaces from '@/components/modals/Workspaces/ModalEditGuestWorkspaces.vue';
import UiDropdownExpandable from '@/components/ui/UiDropdown/UiDropdownExpandable.vue';
import { GoogleCalendar } from '@/types/integrations';
import { useGoogleAccounts } from '@/stores/integrations/google';
import { Workspace } from '@/types/workspaces';
import { useCoachesStore } from '@/stores/coaches/coaches';


export default /*@__PURE__*/_defineComponent({
  __name: 'WidgetWorkSpaces',
  setup(__props) {

const workspacesStore = useWorkspaceStore();
const { setCurrent } = workspacesStore;
const {
  currentWorkspace,
  ownWorkspacesList,
  guestWorkspacesList,
  externalList,
  isCreateOpen,
  isJoinOpen,
  isWidgetVisible,
  activeWorkspacesIds,
} = storeToRefs(workspacesStore);

const coaches = useCoachesStore();

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const tariffStore = useTariffStore();
const { workspacesLeft } = storeToRefs(tariffStore);

const googleAccounts = useGoogleAccounts();
const { accountsList } = storeToRefs(googleAccounts);

const isOwnEditOpen = ref(false);
const isGuestEditOpen = ref(false);
const widgetPending = ref(false);
const isPlansModalVisible = ref(false);

watch(
  () => activeWorkspacesIds.value,
  () => {
    if (
      userData.value?.currentCompany &&
      activeWorkspacesIds.value.length &&
      !activeWorkspacesIds.value.includes(userData.value.currentCompany)
    )
      onChangeWorkspace(activeWorkspacesIds.value[0]);
  },
  { deep: true },
);

const onChangeWorkspace = async (id: number) => {
  try {
    widgetPending.value = true;
    await setCurrent(id);
    await userStore.getUserData();
  } catch (e) {
    console.error(e);
  } finally {
    widgetPending.value = false;
  }
};

const onCreateWorkspace = () => {
  workspacesLeft.value > 0
    ? (isCreateOpen.value = true)
    : (isPlansModalVisible.value = true);
  isWidgetVisible.value = false;
};

const onGoogleCalendarModeChange = async (
  update: Partial<GoogleCalendar> & {
    id: GoogleCalendar['id'];
    accountEmail?: string;
  },
) => {
  if (!update.accountEmail) return;
  const connectedId = accountsList.value.find(
    (a) => a.email === update.accountEmail,
  )?.id;
  if (!connectedId) return;
  await googleAccounts.setCalendar(update, connectedId);
  await workspacesStore.fetchList();
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _directive_skeleton = _resolveDirective("skeleton")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_popover, {
      trigger: _unref(coaches).isCoachesVisible ? undefined : 'click',
      placement: "bottom",
      width: "35.5rem",
      "popper-class": "workspaces-manage",
      visible: _unref(isWidgetVisible),
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (!_unref(coaches).isCoachesVisible && (isWidgetVisible.value = $event)))
    }, {
      reference: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(['widget-workspaces__reference', { active: _unref(isWidgetVisible) }])
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t('workspaces.title')) + " ", 1),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(IconMarker, {
              color: _unref(currentWorkspace)?.color
            }, null, 8, ["color"]),
            _createElementVNode("span", _hoisted_14, _toDisplayString(_unref(currentWorkspace)?.name), 1)
          ]),
          _createVNode(IconChevron, {
            direction: _unref(isWidgetVisible) ? 'top' : 'bottom'
          }, null, 8, ["direction"])
        ], 2)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_unref(ownWorkspacesList)?.length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('workspaces.created')), 1)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _cache[9] || (_cache[9] = _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }, null, -1)),
                  _cache[10] || (_cache[10] = _createElementVNode("span", null, "Voiset", -1)),
                  _createElementVNode("div", {
                    class: "options__edit",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => {
                isOwnEditOpen.value = true;
                isWidgetVisible.value = false;
              })
                  }, _toDisplayString(_ctx.$t('workspaces.edit_btn')), 1)
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass([
              'options__list',
              'with-scroll',
              { pending: widgetPending.value },
            ])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(ownWorkspacesList), (owner) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: owner.id
                    }, [
                      (_openBlock(), _createBlock(WorkspaceItem, {
                        key: owner.id,
                        item: owner,
                        "is-active": _unref(currentWorkspace)?.id === owner.id,
                        onClick: ($event: any) => (onChangeWorkspace(owner.id))
                      }, null, 8, ["item", "is-active", "onClick"]))
                    ]))
                  }), 128))
                ], 2)
              ], 64))
            : _createCommentVNode("", true),
          (_unref(externalList).GOOGLE)
            ? (_openBlock(), _createBlock(UiDropdownExpandable, {
                key: 1,
                static: "",
                class: _normalizeClass({ pending: widgetPending.value })
              }, {
                label: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    (_unref(externalList).GOOGLE.config?.icon)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          alt: "",
                          src: _unref(externalList).GOOGLE.config.icon
                        }, null, 8, _hoisted_7))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(_unref(externalList).GOOGLE.config?.label) + " ", 1),
                    (_unref(externalList).GOOGLE.config?.optionsLink)
                      ? (_openBlock(), _createBlock(_component_el_tooltip, {
                          key: 1,
                          content: "General settings",
                          placement: "right",
                          effect: "customized"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_router_link, {
                              to: { name: _unref(externalList).GOOGLE.config.optionsLink },
                              class: "widget-workspaces__link"
                            }, {
                              default: _withCtx(() => _cache[11] || (_cache[11] = [
                                _createElementVNode("img", {
                                  src: _imports_1,
                                  alt: ""
                                }, null, -1)
                              ])),
                              _: 1
                            }, 8, ["to"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ])
                ]),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(externalList).GOOGLE.content, (group) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: group.account
                    }, [
                      _createVNode(UiDropdownExpandable, {
                        static: _unref(externalList).GOOGLE.content.length === 1,
                        "is-initial-open": 
                  _unref(currentWorkspace)?.id &&
                  group.list.map((w) => w.id).includes(_unref(currentWorkspace).id)
                
                      }, _createSlots({
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            style: _normalizeStyle({
                    overflow: 'hidden',
                    marginLeft:
                      _unref(externalList).GOOGLE.content.length > 1 ? '1rem' : '',
                  })
                          }, [
                            _createElementVNode("div", _hoisted_9, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.list, (item) => {
                                return (_openBlock(), _createBlock(WorkspaceItem, {
                                  key: item.id,
                                  item: item,
                                  "is-active": _unref(currentWorkspace)?.id === item.id,
                                  onClick: ($event: any) => (onChangeWorkspace(item.id)),
                                  onOnGoogleCalendarModeChange: ($event: any) => (
                        onGoogleCalendarModeChange({
                          ...$event,
                          accountEmail: group.account,
                        })
                      )
                                }, null, 8, ["item", "is-active", "onClick", "onOnGoogleCalendarModeChange"]))
                              }), 128))
                            ])
                          ], 4)
                        ]),
                        _: 2
                      }, [
                        (_unref(externalList).GOOGLE.content.length > 1)
                          ? {
                              name: "label",
                              fn: _withCtx(({ isOpen }) => [
                                _createElementVNode("div", _hoisted_8, [
                                  _createVNode(IconChevron, {
                                    direction: isOpen ? 'bottom' : 'right'
                                  }, null, 8, ["direction"]),
                                  _createElementVNode("span", null, _toDisplayString(group.account), 1)
                                ])
                              ]),
                              key: "0"
                            }
                          : undefined
                      ]), 1032, ["static", "is-initial-open"])
                    ]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true),
          (_unref(guestWorkspacesList).length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('workspaces.guest')), 1),
                  _createElementVNode("div", {
                    class: "options__edit",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => {
                isGuestEditOpen.value = true;
                isWidgetVisible.value = false;
              })
                  }, _toDisplayString(_ctx.$t('workspaces.edit_btn')), 1)
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass([
              'options__list',
              'with-scroll',
              { pending: widgetPending.value },
            ])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(guestWorkspacesList), (item) => {
                    return (_openBlock(), _createBlock(WorkspaceItem, {
                      key: item.id,
                      item: item,
                      "is-active": _unref(currentWorkspace)?.id === item.id,
                      onClick: ($event: any) => (onChangeWorkspace(item.id))
                    }, null, 8, ["item", "is-active", "onClick"]))
                  }), 128))
                ], 2)
              ], 64))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", {
              class: "options__row",
              onClick: onCreateWorkspace
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('workspaces.create_btn')), 1),
              _cache[12] || (_cache[12] = _createElementVNode("img", {
                src: _imports_2,
                alt: ""
              }, null, -1))
            ]),
            _createElementVNode("div", {
              class: "options__row",
              onClick: _cache[2] || (_cache[2] = ($event: any) => {
              isJoinOpen.value = true;
              isWidgetVisible.value = false;
            })
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('workspaces.join_btn')), 1),
              _cache[13] || (_cache[13] = _createElementVNode("img", {
                src: _imports_3,
                alt: ""
              }, null, -1))
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["trigger", "visible"]),
    _createVNode(ModalCreateWorkspace, {
      "is-open": _unref(isCreateOpen),
      onClose: _cache[4] || (_cache[4] = ($event: any) => (isCreateOpen.value = false))
    }, null, 8, ["is-open"]),
    _createVNode(ModalEditWorkspaces, {
      list: _unref(ownWorkspacesList),
      "is-open": isOwnEditOpen.value,
      onClose: _cache[5] || (_cache[5] = ($event: any) => (isOwnEditOpen.value = false))
    }, null, 8, ["list", "is-open"]),
    _createVNode(ModalEditGuestWorkspaces, {
      list: _unref(guestWorkspacesList),
      "is-open": isGuestEditOpen.value,
      onClose: _cache[6] || (_cache[6] = ($event: any) => (isGuestEditOpen.value = false))
    }, null, 8, ["list", "is-open"]),
    _createVNode(ModalJoinWorkspace, {
      "is-open": _unref(isJoinOpen),
      onClose: _cache[7] || (_cache[7] = ($event: any) => (isJoinOpen.value = false))
    }, null, 8, ["is-open"]),
    _createVNode(ModalPlanBuy, {
      title: _ctx.$t('card_tariff.no_workspaces_label'),
      onClose: _cache[8] || (_cache[8] = ($event: any) => (isPlansModalVisible.value = false)),
      "is-open": isPlansModalVisible.value
    }, null, 8, ["title", "is-open"])
  ])), [
    [_directive_skeleton, !_unref(currentWorkspace)]
  ])
}
}

})
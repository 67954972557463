<script setup lang="ts">
import { computed, inject, provide, Ref, watch } from 'vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { storeToRefs } from 'pinia';
import { templateRef } from '@vueuse/core';
import {
  autoPlacement,
  autoUpdate,
  offset,
  Placement,
  shift,
  useFloating,
} from '@floating-ui/vue';

const coachesStore = useCoachesStore();
const { activeCard, isCoachesVisible, reference } = storeToRefs(coachesStore);
const card = templateRef<HTMLElement>('card');

const middleware = computed(() => [
  offset(25),
  autoPlacement({
    ...(activeCard.value?.meta?.placement && {
      allowedPlacements: activeCard.value?.meta?.placement as Placement,
    }),
  }),
  shift({ padding: 5, crossAxis: true }),
]);

const { floatingStyles, placement } = useFloating(reference, card, {
  middleware,
  whileElementsMounted: autoUpdate,
  strategy: 'fixed',
});

provide(
  'position',
  computed(() => activeCard.value.attachTo && placement.value),
);

const isMobileResolution = inject<Ref<boolean>>('isMobileResolution');
watch(
  () => isMobileResolution?.value,
  () => {
    if (!activeCard.value?.attachTo) return;
    coachesStore.disableCoaches();
    document
      .querySelector(`#${activeCard.value.attachTo}`)
      ?.classList.remove('coach-highlight');
  },
);
</script>

<template>
  <Component
    :is="activeCard.component"
    :style="floatingStyles"
    v-if="activeCard && isCoachesVisible"
    ref="card"
  />
</template>

<style scoped lang="scss"></style>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"
import _imports_0 from '@images/plus.svg'


const _hoisted_1 = {
  key: 0,
  class: "mobile-sidebar"
}
const _hoisted_2 = { class: "mobile-sidebar__top" }
const _hoisted_3 = { class: "mobile-sidebar__nav-add" }
const _hoisted_4 = { class: "mobile-sidebar__nav-list" }
const _hoisted_5 = { class: "mobile-sidebar__nav-footer" }
const _hoisted_6 = { class: "mobile-sidebar__logout" }

import UiAsideItem from '@/components/ui/UiAsideItem.vue';
import IconLogout from '@/components/icon/nav/IconLogout.vue';
import { menu } from '@/consts/menu';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { storeToRefs } from 'pinia';
import LayoutAsideCreateActions from '@/components/layout/app/LayoutAsideCreateActions.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LayoutMobileAside',
  props: {
    isOpen: { type: Boolean, default: false }
  },
  emits: ['close'],
  setup(__props: any) {

const coachesStore = useCoachesStore();
const { highlightedStyles } = coachesStore;
const { activeCoach, isCoachesVisible } = storeToRefs(coachesStore);




return (_ctx: any,_cache: any) => {
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "blur" }, {
      default: _withCtx(() => [
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("aside", _hoisted_1, [
              _createElementVNode("nav", {
                class: "mobile-sidebar__nav",
                style: _normalizeStyle(_unref(isCoachesVisible) && _unref(highlightedStyles)('sidebar'))
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _renderSlot(_ctx.$slots, "top")
                ]),
                _createVNode(_component_el_popover, {
                  placement: "right",
                  trigger: "click",
                  "popper-class": "is-unstyled",
                  offset: -20
                }, {
                  reference: _withCtx(() => [
                    _createElementVNode("button", _hoisted_3, [
                      _cache[3] || (_cache[3] = _createElementVNode("img", {
                        src: _imports_0,
                        alt: "",
                        class: "new-task"
                      }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('menu.add_task')), 1)
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(LayoutAsideCreateActions, {
                      onCloseSidebar: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menu), ({ label, to, iconName, coach }) => {
                    return (_openBlock(), _createBlock(UiAsideItem, {
                      onCloseSidebar: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
                      key: to,
                      "icon-name": iconName,
                      to: to,
                      id: coach,
                      label: _ctx.$t(label),
                      "show-label": ""
                    }, null, 8, ["icon-name", "to", "id", "label"]))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_router_link, { to: "/logout" }, {
                    default: _withCtx(() => [
                      _createElementVNode("button", _hoisted_6, [
                        _createVNode(IconLogout),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('menu.log_out')), 1)
                      ])
                    ]),
                    _: 1
                  })
                ])
              ], 4)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isOpen && !_unref(activeCoach).isEnabled)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "layout",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}
}

})
<script setup lang="ts">
import { GoogleAccount } from '@/types/integrations';
import { ref, watch } from 'vue';
import GoogleAccountCalendarList from '@/components/integrations/google/settings/GoogleAccountCalendarList.vue';
import GoogleAccountWorkspacesList from '@/components/integrations/google/settings/GoogleAccountWorkspacesList.vue';
import { useCalendarsState } from '@/hooks/integrations/useCalendarsState';
import UiButton from '@/components/control/UiButton.vue';
import IconClose from '@/components/icon/IconClose.vue';
import ModalConfirmGoogle from '@/components/modals/Integrations/google/ModalConfirmGoogle.vue';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import { concatName } from '@/utils/text';

type Props = { account?: GoogleAccount | null };
const props = defineProps<Props>();
const emits = defineEmits(['onApply', 'onClose']);

const isConfirmOpen = ref(false);

const { setInitState, onChangeState, resetState, state } = useCalendarsState();
watch(
  () => [props.account?.id, props.account?.calendars],
  () => {
    props.account?.calendars
      ? setInitState(props.account.calendars)
      : resetState();
  },
);

const onApplyUpdates = async () => {
  const accountId = props.account?.id;
  if (!accountId) return;
  emits('onApply', { state: state.value, accountId });
};
</script>

<template>
  <el-dialog
    :modelValue="!!account"
    width="100rem"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="google-settings">
      <IconClose class="google-settings__close" @click="isConfirmOpen = true" />
      <h3>Select additional settings for easy use of the integration</h3>
      <div class="google-settings__account">
        <UiAvatar
          :fallback-name="concatName(account?.firstName, account?.secondName)"
        />
        <span>{{ `${account?.firstName}&nbsp;${account?.secondName}` }}</span>
        <span class="disabled-text">{{ account?.email }}</span>
      </div>
      <div class="google-settings__content">
        <h3>
          Customize how your Google calendars and Voiset workspaces display with
          each other
        </h3>
        <GoogleAccountCalendarList
          :calendars="state"
          @onStatusChange="onChangeState"
          @onModeChange="onChangeState"
        />
        <!--        <GoogleAccountWorkspacesList />-->
      </div>
      <ui-button class="google-settings__connect" @click="onApplyUpdates">
        Done
      </ui-button>
      <teleport to="body">
        <ModalConfirmGoogle
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :is-open="isConfirmOpen"
          title="Stop setting"
        >
          <div class="google-settings__remove-confirm">
            <img src="@images/integrations/google-calendar.svg" alt="" />
            <span>
              Your settings are not saved, but you can always go back and
              customize your account.
            </span>
          </div>
          <template #footer>
            <div class="notification-modal__btns">
              <ui-button
                plain
                @click.stop="
                  isConfirmOpen = false;
                  $emit('onClose');
                "
              >
                Stop
              </ui-button>
              <ui-button @click.stop="isConfirmOpen = false">
                Continue settings
              </ui-button>
            </div>
          </template>
        </ModalConfirmGoogle>
      </teleport>
    </div>
  </el-dialog>
</template>

<style scoped lang="scss">
.google-settings {
  color: #1a2a39;
  @include flex-column(2rem);
  padding: 3rem;

  .with-border {
    padding-right: 2rem;
    border-right: 0.1rem solid #dde3ec;
  }

  & > h3 {
    @include f20;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    cursor: pointer;
  }

  &__account {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    column-gap: 1rem;
    text-align: left;
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid #dde3ec;

    & > *:first-child {
      grid-row: span 2;
    }
  }

  &__content {
    display: grid;
    gap: 2rem;
    min-height: 30rem;

    & > * {
      grid-column: span 2;
    }

    & > h3 {
      @include f20;
    }
  }

  &__connect {
    width: fit-content;
    margin-left: auto;
  }

  &__remove-confirm {
    @include flex-column(2rem);
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@images/agenda/coaches/eye.svg'


import UiCoachCard from '@/components/ui/UiCoachCard.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'CoachViewOnly',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiCoachCard, { class: "coach-week-view-only" }, {
    title: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "coach-week-view-only__title" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }),
        _createTextVNode(" You can only view tasks, but not edit or reschedule in Voiset. ")
      ], -1)
    ])),
    content: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "coach-week-view-only__content" }, " Tasks from your connected calendars that cannot be edited in Voiset, but any changes made to third-party calendars will show up in Voiset. ", -1)
    ])),
    _: 1
  }))
}
}

})
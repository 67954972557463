<template>
  <el-tooltip :content="$t('tooltips')" placement="bottom" effect="customized">
    <div
      class="widget-coach"
      :class="{ enabled: isCoachesEnabled }"
      @click.stop="store.resolveCoach"
    >
      <icon-question />
    </div>
  </el-tooltip>
</template>

<script setup lang="ts">
import { useCoachesStore } from '@/stores/coaches/coaches';
import { storeToRefs } from 'pinia';
import IconQuestion from '@/components/icon/IconQuestion.vue';

const store = useCoachesStore();
const { isCoachesEnabled } = storeToRefs(store);
</script>

<style scoped lang="scss">
.widget-coach {
  display: flex;
  justify-content: center;
  padding: 1rem;
  align-items: center;
  cursor: pointer;
  @include gray-bg-hover;
  border-radius: 50%;

  & > svg {
    color: #1a2a39;
  }

  &.enabled {
    background: var(--color-primary);

    & > svg {
      color: #fcfcfc;
    }
  }

  @media (max-width: 640px) {
    display: none;
  }
}
</style>

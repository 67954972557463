<script setup lang="ts">
import UiCoachCard from '@/components/ui/UiCoachCard.vue';
import { inject, Ref } from 'vue';

const isMobileResolution = inject<Ref<boolean>>('isMobileResolution');
</script>

<template>
  <UiCoachCard
    class="coach-week-drag"
    :max-width="isMobileResolution ? '30rem' : '35vw'"
  >
    <template #title>
      <div class="coach-week-drag__title">
        Drag and drop tasks from your schedule to your task list for the day.
      </div>
    </template>
    <template #content>
      <div class="coach-week-drag__content">
        Tasks can be dragged back and forth, this is handy for quickly planning
        your day. Try it now!
        <video
          loop
          muted
          autoplay
          playsinline
          src="https://storage.googleapis.com/public_media_bucket/coaches/day-drag-head.mp4"
          width="100%"
          v-if="!isMobileResolution"
        />
      </div>
    </template>
  </UiCoachCard>
</template>

<style scoped lang="scss">
.coach-week-drag {
  &__content {
    padding: 0 2rem;
    @include f14;

    & > img {
      width: 100%;
    }
  }

  &__title {
    @include flex-row(1rem);
    @include f16;
    align-items: center;
    color: var(--color-primary);
    padding: 2rem 4rem 0 2rem;
  }
}
</style>

import { ActiveCoach, CoachCard, CoachName } from '@/types/coaches';
import { computed, Ref, ref, watch } from 'vue';

export const useCoachControl = (
  coaches: Ref<Record<CoachName, CoachCard[]>>,
) => {
  const isCoachesEnabled = ref(false);

  const activeCoach = ref<ActiveCoach>({
    name: 'dashboard',
    activeStep: 1,
    steps: coaches.value['dashboard'].length,
    isEnabled: false,
  });

  const activeCard = computed(
    () =>
      coaches.value[activeCoach.value.name]?.[activeCoach.value.activeStep - 1],
  );

  const isCoachesVisible = computed(
    () => isCoachesEnabled.value && activeCoach.value.isEnabled,
  );

  function disableCoaches() {
    isCoachesEnabled.value = false;
  }

  function enableCoaches() {
    isCoachesEnabled.value = true;
  }

  function disableActiveCoach() {
    activeCoach.value.isEnabled = false;
    activeCoach.value.activeStep = 1;
  }

  function enableActiveCoach() {
    activeCoach.value.isEnabled = true;
  }

  function resolveCoach() {
    isCoachesEnabled.value ? disableCoaches() : resetCoach();
  }

  function setCoach(name: CoachName) {
    activeCoach.value = {
      name,
      activeStep: 1,
      steps: coaches.value[name].length,
      isEnabled: true,
    };
  }

  function resetCoach(name?: CoachName) {
    activeCoach.value = {
      ...activeCoach.value,
      ...(name && { name }),
      isEnabled: true,
      activeStep: 1,
    };
    enableCoaches();
  }

  function setStep(step: number) {
    if (step < 1) return;
    if (step > activeCoach.value.steps) return disableCoaches();
    activeCoach.value.activeStep = step;
  }

  watch(
    () => [activeCoach.value.name, activeCoach.value.isEnabled],
    () => activeCoach.value.steps === 0 && disableActiveCoach(),
    { immediate: true },
  );

  return {
    isCoachesEnabled,
    isCoachesVisible,
    activeCoach,
    activeCard,
    disableCoaches,
    enableCoaches,
    disableActiveCoach,
    enableActiveCoach,
    resetCoach,
    resolveCoach,
    setCoach,
    setStep,
  };
};

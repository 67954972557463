import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "coach-calendar__content" }
const _hoisted_2 = { class: "coach-calendar__desc" }
const _hoisted_3 = { class: "coach-calendar__legend" }
const _hoisted_4 = { class: "coach-calendar__legend-item" }
const _hoisted_5 = { class: "coach-calendar__legend-item" }
const _hoisted_6 = { class: "coach-calendar__legend-item" }
const _hoisted_7 = { class: "coach-calendar__legend-item" }

import UiCoachCard from '@/components/ui/UiCoachCard.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'CoachDayCalendar',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiCoachCard, {
    title: "Your workload is always in full view!",
    class: "coach-calendar"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('coaches.home.2.content1')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[0] || (_cache[0] = _createElementVNode("div", {
              class: "coach-calendar__marker",
              style: {"border-color":"#c8f48d"}
            }, null, -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('coaches.home.2.content2')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("div", {
              class: "coach-calendar__marker",
              style: {"border-color":"#ec7975"}
            }, null, -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('coaches.home.2.content3')), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[2] || (_cache[2] = _createElementVNode("div", {
              class: "coach-calendar__marker",
              style: {"border-color":"#f19f3c"}
            }, null, -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('coaches.home.2.content4')), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[3] || (_cache[3] = _createElementVNode("div", {
              class: "coach-calendar__marker",
              style: {"border-color":"#bdbdbd"}
            }, null, -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('coaches.home.2.content5')), 1)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})